<template>
  <div>
    <div class="search-box">
      <h2 class="search-title border-bottom">按条件搜索</h2>
      <Form class="search-form"
            :label-width="85"
           >
        <Form-item label="选择对象">
          <Row v-if="!branchShow">
            <Col span="5">
            <span class="sub-label">省份：</span>
            <div class="sub-content">
              <Select clearable
                      v-model="searchData.pid"
                      placeholder="请选择..."
                      @on-change="changePro">
                <Option :value="v.id"
                        v-for="v in proList"
                        :key="v.id">{{v.name}}</Option>
              </Select>
            </div>
            </Col>
            <Col span="5"
                offset="1">
            <span class="sub-label">城市：</span>
            <div class="sub-content">
              <Select clearable
                      v-model="searchData.cid"
                      placeholder="请选择..."
                      @on-change="changeCity">
                <Option :value="v.id"
                        v-for="v in cityList"
                        :key="v.id">{{v.name}}</Option>
              </Select>
            </div>
            </Col>
            <Col span="5"
                offset="1">
            <span class="sub-label">门店：</span>
            <div class="sub-content">
              <Select clearable
                      v-model="searchData.sid"
                      placeholder="请选择..."
                      @on-change="changeStore">
                <Option :value="v.id"
                        v-for="v in storeList"
                        :key="v.id">{{v.name}}</Option>
              </Select>
            </div>
            </Col>
            <Col span="5"
                offset="1">
            <span class="sub-label">会员：</span>
            <div class="sub-content">
              <Select clearable
                      v-model="uid"
                      placeholder="请选择..."
                      filterable>
                <Option :value="v.id"
                        v-for="v in userList"
                        :key="v.id">{{v.true_name}}</Option>
              </Select>
            </div>
            </Col>
          </Row>
        <Row v-else>
            <Col span="5">
              <span class="sub-label">省份：</span>
                <div class="sub-content">
                  <Input disabled v-model="proName"></Input>
                </div>
            </Col>
            <Col span="5"
                offset="1">
            <span class="sub-label">城市：</span>
            <div class="sub-content">
              <Input disabled v-model="cityName"></Input>
            </div>
            </Col>
            <Col span="5"
                offset="1">
            <span class="sub-label">门店：</span>
            <div class="sub-content">
              <Input disabled v-model="sName"></Input>
            </div>
            </Col>
            <Col span="5"
                offset="1">
            <span class="sub-label">会员：</span>
            <div class="sub-content">
              <Select clearable
                      v-model="uid"
                      placeholder="请选择..."
                      filterable>
                <Option :value="v.id"
                        v-for="v in userList"
                        :key="v.id">{{v.true_name}}</Option>
              </Select>
            </div>
            </Col>
          </Row>
        </Form-item>
        <Form-item label="报告时间">
          <Date-picker
            format="yyyy-MM-dd"
            type="daterange"
            placeholder="选择日期"
            @on-change="handleChangeDate"
            style="width:300px;margin-right:10px;"></Date-picker>
          <Button type="primary" @click="handleQuery">搜索</Button>
        </Form-item>
      </Form>
    </div>
    <div class="content-box mb20">
      <h2 class="content-title border-bottom">训练报告</h2>
      <div class="content-body">
        <Table
          :columns="trainReportColumns"
          :data="trainReportList"
        ></Table>
        <div>
          <span class="records">共 {{trainReportTotal}} 条记录</span>
        </div>
        <div class="page-nations-wrapper">
          <div class="page-nations">
            <Page
              :total="trainReportTotal"
              :current="trainReportCurrentPage"
              :page-size="reportData.size"
              @on-change="changeReportPage"></Page>
          </div>
        </div>
      </div>
    </div>
    <div class="content-box">
      <h2 class="content-title border-bottom">
       <span>训练数据</span>
       <Button type="primary" @click="makeReport">生成训练报表</Button>
      </h2>
      <div class="content-body">
        <Table
          :columns="trainDataColumns"
          :data="trainDataList"
          ref="table"
          @on-selection-change="handleDataSelect"></Table>
        <div>
          <span class="records">共 {{trainDataTotal}} 条记录</span>
        </div>
        <div class="page-nations-wrapper">
          <div class="page-nations">
            <Page
              :total="trainDataTotal"
              :current="trainDataCurrentPage"
              :page-size="trainData.size"
              @on-change="changeDataPage"></Page>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import comparisonService from '@/services/comparisonService';
import qualityService from '@/services/qualityService';
import MemberService from '@/services/memberService';

export default {
	data() {
		return {
			options: {
				disabledDate(date) {
					return date && date.valueOf() > Date.now();
				},
			},
			searchData: {
				page: 1,
				size: 1000,
				pid: '',
				cid: '',
				sid: '',
				simple_query: 1,
			},
			branchShow: false,
			branch_id: [],
			power: [],
			proName: '',
			cityName: '',
			sName: '',
			storeList: [],
			proList: [],
			cityList: [],
			userList: [],
			uid: '',
			trainData: {
				index: 1,
				start: '',
				end: '',
				page: 1,
				size: 10,
			},
			trainDataColumns: [
				{ type: 'selection', width: 60, align: 'center' },
				{
					title: '训练日期',
					align: 'center',
					render(h, params) {
						return h('span', params.row.xlsj.substring(0, 10));
					},
				},
				{ title: '训练时长', key: 'sportTime', align: 'center' },
				{ title: '训练时最大心率', key: 'maxHeart', align: 'center' },
				{ title: '平均心率', key: 'avgHeart', align: 'center' },
				{ title: '总热量消耗', key: 'calories', align: 'center' },
				{ title: '出具报告时间', key: 'cjbgrq', align: 'center' },
			],
			trainDataList: [],
			trainDataTotal: 0,
			trainDataCurrentPage: 1,
			selectedDataItem: [],
			reportData: {
				start_time: '',
				end_time: '',
				member_id: '',
				page: 1,
				size: 10,
			},
			trainReportColumns: [
				{ title: '报告名称', key: 'bgmc', align: 'center' },
				{ title: '出具日期', key: 'bgrq', align: 'center' },
				{ title: '咨询师', key: 'zxs', align: 'center' },
				{ title: '检测师', key: 'jcs', align: 'center' },
				{ title: '解读状态', key: 'jdzt', align: 'center' },
				{
					title: '操作',
					key: 'action',
					width: 150,
					align: 'center',
					render: (h, params) => {
						const data = params.row;
						const actions = [
							h('a', {
								class: 'icon_eye',
								attrs: {
									target: '_blank',
									href: `/#/base/member/detail/${this.uid}/Train/19/${data.id}`,
								},
								style: { margin: '0' },
							}),
						];
						if (data.bg_url) {
							actions.push(
								h('Icon', {
									attrs: {
										type: 'md-arrow-round-down',
										size: 22,
										color: '#fdad00',
									},
									style: {
										marginLeft: '10px',
										cursor: 'pointer',
									},
									on: {
										click: () => {
											const a = document.createElement('a');
											a.href = data.bg_url;
											a.style.display = 'none';
											a.setAttribute(
												'download',
												decodeURI(data.bg_url.substring(data.bg_url.lastIndexOf('/') + 1)),
											);
											document.body.appendChild(a);
											a.click();
											document.body.removeChild(a);
										},
									},
								}),
							);
						}
						return h('div', actions);
					},
				},
			],
			trainReportList: [],
			trainReportTotal: 0,
			trainReportCurrentPage: 1,
			selectedReportItem: [],
		};
	},
	created() {
		let list = JSON.parse(localStorage.getItem('userInfo'));
		qualityService.getQualitySelect().then((data) => {
			this.power = list.user_info.power;
			if (this.power.indexOf('会员管理--查看所属店面会员信息权限') === -1) {
				this.branchShow = false;
			} else {
				if (
					this.power.indexOf('会员管理--查看全国会员信息权限') === -1 &&
					this.power.indexOf('会员管理--查看所属区域会员信息权限') === -1
				) {
					this.branchShow = true;
					this.branch_id = list.user_info.branch;
					this.searchData.sid = this.branch_id[2];
					this.searchData.cid = this.branch_id[1];
					this.searchData.pid = this.branch_id[0];
					this.proName = list.user_info.pro_name;
					this.cityName = list.user_info.city_name;
					this.sName = list.user_info.branch_name;
					this.changeStore();
				}
			}
			this.proList = data.branch.pro;
			this.city = data.branch.city;
			this.store = data.branch.store;
		});
	},
	methods: {
		// 根据省份选择城市
		changePro() {
			this.cityList = this.city[this.searchData.pid];
			this.searchData.cid = '';
		},
		// 根据城市选择门店
		changeCity() {
			this.storeList = this.store[this.searchData.cid];
			this.searchData.sid = '';
		},
		// 根据门店选择用户
		changeStore() {
			if (this.searchData.sid) {
				comparisonService.getUserList(this.searchData).then((data) => {
					this.userList = data.list;
				});
			} else {
				this.userList = [];
			}
		},
		handleQuery() {
			if (!this.uid) {
				this.$Message.error('请选择会员');
				return;
			}
			this.getReportData();
			this.getTrainData();
		},
		getTrainData() {
			this.trainData.member_id = this.uid;
			MemberService.zhaikeQuery(this.trainData).then((data) => {
				this.selectedDataItem = [];
				this.trainDataList = data.list;
				this.trainDataTotal = data.row_size;
				this.trainDataCurrentPage = +data.cur_page;
				this.trainDataList.forEach((item) => {
					item.xlsj = item.xlsj.substring(0, 16);
				});
			});
		},
		changeDataPage(page) {
			this.trainData.page = page;
			this.getTrainData();
		},
		handleChangeDate(value) {
			this.trainData.start = value[0];
			this.trainData.end = value[1];
			this.reportData.start_time = value[0];
			this.reportData.end_time = value[1];
		},
		handleDataSelect(selection) {
			this.selectedDataItem = selection.map((item) => item.record_id);
		},
		makeReport() {
			const amount = this.selectedDataItem.length;
			if (amount > 1 && amount < 5) {
				const params = {
					member_id: this.uid,
					ids: JSON.stringify(this.selectedDataItem),
				};
				MemberService.makeTrainReport(params).then((data) => {
					window.open(`/#/base/member/detail/${this.uid}/Train/19/${data.id}`);
				});
			} else {
				this.$Message.error('请选择 2-4 条数据');
			}
		},
		getReportData() {
			this.reportData.member_id = this.uid;
			MemberService.trainReportList(this.reportData).then((data) => {
				this.trainReportList = data.list;
				this.trainReportTotal = data.row_size;
				this.trainReportCurrentPage = +data.cur_page;
			});
		},
		changeReportPage(page) {
			this.reportData.page = page;
			this.getReportData();
		},
	},
};
</script>

<style lang="less" scoped>
.sub-label {
  vertical-align: middle;
  float: left;
}

.sub-content {
  margin-left: 50px;
}
</style>
